import React, { useEffect, useState } from "react"
import Layout from "../components/layout/layout"
import Seo from "../components/seo/seo"
import tracking from "../utils/tracking"
import "./404.scss"
import links from "../utils/links"

const NotFoundPage = () => {
  const [campagne, setCampagne] = useState("")
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
    }
  }, [])
  useEffect(() => {
    tracking
    /** Tracking */
    tracking.track("typeTemplate", "echecdiagnosticpage")
    tracking.track("page_name", "Résultat diagnostic echec")
    tracking.track(
      "page_error",
      typeof window !== `undefined` ? window.location.href : ""
    )
    tracking.trackInit()
  }, [])
  return (
    <Layout>
      <Seo title="404: Not found" isCanonical={false} />
      <div className="container-404 d-f fd-c jc-c ai-c px-5">
        <div
          className="d-if jc-c ai-c  bc-6 br-50 mb-3"
          style={{
            height: "48px",
            minWidth: "48px",
            width: "48px",
            padding: "14px",
          }}
        >
          <span className="icon-warning"></span>
        </div>
        <h1 className=" ta-c cg-7 fs-28 ff-gb lh-32">Erreur 404</h1>
        <h2 className="sub-title-error ta-c cg-7 fs-14 lh-24 ff-gbook mb-0">
          Malheureusement, nous ne sommes pas en mesure d’afficher le contenu
          demandé.
        </h2>
        <p className="ta-c cg-7 fs-14 lh-24 ff-gbook">
          Veuillez réeessayer plus tard.
        </p>
        <a className="c-1 ff-gbook ta-c" href={links.getLink("home") + campagne}>
          Revenir à l’accueil
        </a>
      </div>
    </Layout>
  )
}

export default NotFoundPage
